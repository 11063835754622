import React, { useState } from "react";
import { StyleSheet, View, Text, Pressable, TouchableOpacity, Dimensions, ScrollView } from "react-native";
import { useDispatch } from "react-redux";
import { ModalActions } from "@redux";
import { ModalTemplate } from "@atomic/templates";
import { TestIDs } from "@utils";
import { I18n } from "react-redux-i18n";
import { PreferredZoneType } from "@foodi/core";
import { Colors } from "@constants/Colors";
import { PreferredZonesThunks } from "@modules";
import { PreferredZone, PreferredZoneProps } from "./PreferredZone";

interface PreferredZoneModalProps extends PreferredZoneProps{
	idHoldingView?: string;
	preferredZones: PreferredZoneType[];
}

const ICON_SIZE = 18;

const zoneItem = (item: any, index: number, styles: any, isSelected: boolean, onSelect: any) => {
	return (
		<TouchableOpacity
			key={`PreferedZones_${index}_${item}`}
			style={[styles.touchable, isSelected && styles.touchableSelected]}
			onPress={() => onSelect(item)}
		>
			<Text style={isSelected ? [styles.nextZone, styles.firstZone] : styles.nextZone} numberOfLines={1} ellipsizeMode="tail">
				{item.name}
			</Text>
		</TouchableOpacity>
	);
};

export const PreferredZoneModal = React.memo((props: PreferredZoneModalProps) => {
	const dispatch = useDispatch();

	const [firstPreferredZone, setFirstPreferredZone] = useState<PreferredZoneType>(props?.preferredZones[0]);
	const [selectedZone, setSelectedZone] = useState<PreferredZoneType>(props?.preferredZones[0]);

	const handleCloseModal = () => {
		dispatch(ModalActions.setOpen(false));
	};

	const handleSelectZone = (e: any) => {
		setFirstPreferredZone(e);
		setSelectedZone(e);
	};

	const handleValidateSelectZone = (e: any) => {
		try {
			const sanitizeSelectZone = {id: selectedZone.id, name: selectedZone.name};
			dispatch(PreferredZonesThunks.updatePreferredZones({
				input: {
					idHoldingView: props.idHoldingView,
					preferredZones: [sanitizeSelectZone],
				}
			}));
			dispatch(PreferredZonesThunks.setSelectedPreferredZones(selectedZone));
			handleCloseModal();
		} catch (e) {
			console.error(`ERROR - updatePreferredZones - ${e}`);
		}
	};

	return (
		<ModalTemplate
			forwardTestID={TestIDs.preferredZone.modals.container}
			forwardCloseTestID={TestIDs.preferredZone.actions.closeModal}
			style={styles.modalStyle}
			closeXButtonStyle={styles.closeXButtonStyle}
			handleClose={handleCloseModal}
			closeOnClickOutside
		>
			<PreferredZone {...props} />
		</ModalTemplate>
	);
});

const styles = StyleSheet.create({
	header: {},
	content: {},
	title: {
		fontFamily: "Manrope-Bold",
		fontSize: 22,
		lineHeight: 30,
		color: Colors.foodiBlack,
		textAlign: "center",
	},
	subTitle: {
		textAlign: "center",
		fontFamily: "Manrope-Bold",
		fontSize: 13,
		color: Colors.foodiBlack,
		marginTop: 12,
	},
	listZoneContainer: {
		width: "90%",
		padding: 16,
		borderRadius: 8,
	},
	gridContainer: {
		flexDirection: "row",
		flexWrap: "wrap",
		justifyContent: "space-between",
	},
	firstZone: {
		backgroundColor: Colors.foodiDefault,
	},
	nextZone: {
		textAlign: "center",
		fontFamily: "Manrope-Bold",
		fontSize: 13,
		borderColor: Colors.foodiDefault,
		borderRadius: 8,
		width: 100,
		padding: 8,
		borderWidth: 1,
	},
	closeXButtonStyle: {
		top: -15,
		right: -5,
	},
	modalStyle: {
		width: 414,
		maxHeight: 650,
	},
	btnArea: {
		paddingTop: 8,
		alignItems: "center",
		textAlign: "center",
	},
	validateBtn: {
		backgroundColor: Colors.foodiDefault,
		lineHeight: 40,
		fontFamily: "Manrope-Bold",
		borderRadius: 8,
		height: 40,
		width: 220,
	},
	touchable: {
		width: "30%",
		height: 40,
		marginTop: 10,
		marginRight: 10,
		alignItems: "center",
		justifyContent: "flex-start",
		flexDirection: "row",
	},
	touchableSelected: {
		borderWidth: 0,
	},
	touchableText: {
		color: Colors.foodiBlack,
		maxWidth: 200,
	},
	touchableTextSelected: {
		maxWidth: 200,
	},
	iconContainer: {
		alignItems: "center",
		borderRadius: 4,
		justifyContent: "center",
		width: 20,
		height: 20,
		marginRight: 5,
	},
	buttonApply: {
		position: "absolute",
		bottom: 0,
		marginBottom: 0,
		width: Dimensions.get("window").width,
		alignSelf: "center",
		alignItems: "center",
		justifyContent: "center",
		height: 60,
		padding: 15,
		borderTopRightRadius: 0,
		borderTopLeftRadius: 0,
		borderBottomRightRadius: 0,
		borderBottomLeftRadius: 0,
	},
});

