import { PreferredZoneType } from "@foodi/core";
import { createReducer, GlobalState, ThunkResult } from "@redux";
import { Action } from "@redux/action";
import { displayToastError, arraySortByName } from "@utils";

export interface PreferredZonesState {
	preferredZones: PreferredZoneType[];
	selectedPreferredZone: PreferredZoneType | null;
	maximumPreferredZones: number;
	setEnablePreferredZone: boolean;
}

/*************  Actions  ****************/
const ActionTypes = {
	SET_PREFERRED_ZONES: "PreferredZones/SET_PREFERRED_ZONES",
	SET_SELECTED_PREFERRED_ZONES: "PreferredZones/SET_SELECTED_PREFERRED_ZONES",
	SET_MINIMUM_PREFERRED_ZONES: "PreferredZones/SET_MINIMUM_PREFERRED_ZONES",
	SET_ENABLE_PREFERRED_ZONE: "PreferredZones/SET_ENABLE_PREFERRED_ZONE",
	SET_RESET_PREFERRED_ZONES: "PreferredZones/SET_RESET_PREFERRED_ZONES",
};

const ActionCreators = {
	setPreferredZones: (preferredZones: PreferredZoneType[]): Action<PreferredZoneType[]> => ({
		type: ActionTypes.SET_PREFERRED_ZONES,
		payload: preferredZones,
	}),
	setSelectedPreferredZones: (selectedPreferredZone: PreferredZoneType | null): Action<PreferredZoneType | null> => ({
		type: ActionTypes.SET_SELECTED_PREFERRED_ZONES,
		payload: selectedPreferredZone,
	}),
	setMaximumPreferredZones: (maximumPreferredZones: number): Action<number> => ({
		type: ActionTypes.SET_MINIMUM_PREFERRED_ZONES,
		payload: maximumPreferredZones,
	}),
	setEnablePreferredZone: (setEnablePreferredZone: boolean): Action<boolean> => ({
		type: ActionTypes.SET_ENABLE_PREFERRED_ZONE,
		payload: setEnablePreferredZone,
	}),
};

const ThunkActionCreators = {
	setPreferredZones: (preferredZones: PreferredZoneType[]): ThunkResult<void> => async (dispatch) => {
		dispatch(ActionCreators.setPreferredZones(preferredZones));
	},
	setSelectedPreferredZones: (selectedPreferredZone: PreferredZoneType | null): ThunkResult<void> => async (dispatch) => {
		dispatch(ActionCreators.setSelectedPreferredZones(selectedPreferredZone));
	},
	setMaximumPreferredZones: (maximumPreferredZones: number): ThunkResult<void> => async (dispatch) => {
		dispatch(ActionCreators.setMaximumPreferredZones(maximumPreferredZones));
	},
	setEnablePreferredZone: (setEnablePreferredZone: boolean): ThunkResult<void> => async (dispatch) => {
		dispatch(ActionCreators.setEnablePreferredZone(setEnablePreferredZone));
	},
	resetPreferredZonesStore: (): ThunkResult<void> => async (dispatch) => {
		dispatch(ActionCreators.setPreferredZones([]));
		dispatch(ActionCreators.setSelectedPreferredZones(null));
		dispatch(ActionCreators.setMaximumPreferredZones(0));
		dispatch(ActionCreators.setEnablePreferredZone(false));
	},
	updatePreferredZones: (request: any): ThunkResult<void> => async (dispatch, getState, { getDependencies }) => {
		try {
			const { updatePreferredZones } = getDependencies();
			return updatePreferredZones.execute(request);
		} catch (error) {
			displayToastError(dispatch);
			return Promise.reject(error);
		}
	},
};

/*************  Reducer  ****************/
const initialState: PreferredZonesState = {
	preferredZones: [],
	selectedPreferredZone: null,
	maximumPreferredZones: 0,
	setEnablePreferredZone: false,
};

const Reduction = {
	setPreferredZones: (state: PreferredZonesState, { payload: preferredZones }: Action<PreferredZoneType[]>): PreferredZonesState => ({
		...state,
		preferredZones: preferredZones.sort(arraySortByName),
	}),
	setSelectedPreferredZones: (state: PreferredZonesState, { payload: selectedPreferredZone }: Action<PreferredZoneType>): PreferredZonesState => ({
		...state,
		selectedPreferredZone,
	}),
	setMaximumPreferredZones: (state: PreferredZonesState, { payload: maximumPreferredZones }: Action<number>): PreferredZonesState => ({
		...state,
		maximumPreferredZones,
	}),
	setEnablePreferredZone: (state: PreferredZonesState, { payload: setEnablePreferredZone }: Action<boolean>): PreferredZonesState => ({
		...state,
		setEnablePreferredZone,
	}),
	setResetPreferredZones: (state: PreferredZonesState): PreferredZonesState => initialState,
};

const reducer = createReducer(initialState, {
	[ActionTypes.SET_PREFERRED_ZONES]: Reduction.setPreferredZones,
	[ActionTypes.SET_SELECTED_PREFERRED_ZONES]: Reduction.setSelectedPreferredZones,
	[ActionTypes.SET_MINIMUM_PREFERRED_ZONES]: Reduction.setMaximumPreferredZones,
	[ActionTypes.SET_ENABLE_PREFERRED_ZONE]: Reduction.setEnablePreferredZone,
	[ActionTypes.SET_RESET_PREFERRED_ZONES]: Reduction.setResetPreferredZones,
});

//SELECTOR
export const getPreferredZones = (state: GlobalState) => state?.preferredZones?.preferredZones;
export const getSelectedPreferredZone = (state: GlobalState) => state?.preferredZones?.selectedPreferredZone;
export const getMaximumPreferredZones = (state: GlobalState) => state?.preferredZones?.maximumPreferredZones;
export const getEnablePreferredZone = (state: GlobalState) => state?.preferredZones?.setEnablePreferredZone;

export default reducer;
export {
	reducer as PreferredZonesReducer,
	ActionTypes as PreferredZonesActionTypes,
	ActionCreators as PreferredZonesActions,
	ThunkActionCreators as PreferredZonesThunks,
};
