"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpdatePreferredZones = void 0;
class UpdatePreferredZones {
    constructor(preferredZone) {
        this.preferredZone = preferredZone;
    }
    execute(request) {
        return this.preferredZone.updatePreferredZones(request);
    }
}
exports.UpdatePreferredZones = UpdatePreferredZones;
