"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FPrefferedZonesService = void 0;
const updatePreferredZones_1 = require("../queries/updatePreferredZones");
class FPrefferedZonesService {
    constructor(apolloClient) {
        this.apolloClient = apolloClient;
    }
    updatePreferredZones(variables) {
        try {
            return this.apolloClient.mutate({
                mutation: updatePreferredZones_1.UPDATE_PREFERRED_ZONES,
                variables,
            });
        }
        catch (error) {
            return Promise.reject(error);
        }
    }
}
exports.FPrefferedZonesService = FPrefferedZonesService;
