"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UPDATE_PREFERRED_ZONES = void 0;
const client_1 = require("@apollo/client");
exports.UPDATE_PREFERRED_ZONES = client_1.gql `
  mutation updatePreferredZones($input: InputUpdatePreferredZones!) {
    updatePreferredZones(input: $input) {
      id
    }
  }
`;
