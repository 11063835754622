"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_BRAND = void 0;
const client_1 = require("@apollo/client");
exports.GET_BRAND = client_1.gql `
  query getBrand {
    getUser {
      id
      guests {
        edges {
          node {
            id
            holding {
              id
              brand
              brandModel {
                id
                name
                whiteLogo
                blackLogo
              }
            }
          }
        }
      }
      currentHoldingView {
        holding {
          id
          brand
          externalServices {
            title
            content
            active
          }
          enabledPreferredZones
          maximumPreferredZones
        }
        preferredZones {
          id
          name
        }
      }
    }
  }
`;
