import React from "react";

interface IProps {
  size?: number;
  color?: string
}

export const Heart: React.FC<IProps> = ({color, size}) => (
<svg 
  xmlns="http://www.w3.org/2000/svg" 
  xmlnsXlink="http://www.w3.org/1999/xlink" 
  width={`${size ? size : 20}px`}
  height={`${size ? size : 20}px`}
>
	<path 
    fill={color ? color : "currentColor"}
    d="M10 18L8.5500002 16.705177C3.4000001 12.12425 0 9.1029968 0 5.3950953C0 2.373842 2.4200001 0 5.5 0C7.2399998 0 8.9099998 0.79455042 10 2.0501363C11.09 0.79455042 12.76 0 14.5 0C17.58 0 20 2.373842 20 5.3950953C20 9.1029968 16.6 12.12425 11.45 16.714987L10 18Z"/>
</svg>

);
