import {
  InitializeAuth0Client,
  Login,
  RetrieveTokens,
  RefreshToken,
  GetPaymentRequest,
  GetPaymentStatus,
  GetBalance,
  GetGuestPaymentProfile,
  GetServices,
  GetBrand,
  GetMarketingCards,
  GetPublishedMarketingCardsNew,
  GetOfferTemplates,
  GetPointsOfSaleWithMenu,
  GetTicket,
  GetI18nProviderLocale,
  GetProfileSectionsData,
  GetTransactions,
  GetUserInfoForTracking,
  GetUserProfile,
  GetOrders,
  GetOrder,
  GetOrderReceipt,
  GetOrderCC,
  GetBooking,
  GetBookingOrders,
  GetHoldingWithRecipeStatus,
  GetPos,
  GetOnePos,
  GetPointsOfSale,
  UpsertOrder,
  ConfirmOrder,
  AddOrderRating,
  GetCartSummaryAdmissionAndPrice,
  GetOfferSlots,
  CreateBookingOrder,
  UpdateBookingOrderSlot,
  CancelBookingOrder,
  CancelOrder,
  GetBookingTemplateOffer,
  UpdateLanguage,
  UpdateOptins,
  UpdateOptouts,
  UpdateVirtualTicket,
  GetBrandTheme,
  CheckEmail,
  GetNearHoldings,
  SignUp,
  AddUserHoldingView,
  SwitchUserHoldingView,
  GetEdenredDailyBalance,
  GetEdenredAuthorizationURL,
  GenerateEdenredTokens,
  GetUserHoldingView,
  MigrateToHoldingView,
  DeleteUserHoldingView,
  UpdateUserHoldingView,
  SendUserEmailVerification,
  LoginSSO,
  GetOffer,
  CheckTablesAvailability,
  DeleteAccount,
  LoginIDP,
  GetScolapassPayments,
  GetScolapassInvoices,
  GetInvoiceScolapassFile,
  SendResetPasswordEmail,
  GetAllOrders,
  GetPickupPoints,
  GetUserLastUsedPickupPoint,
  RecentOrdersToRate,
  DiscardOrderRatingNotification,
  GetEpointageServices,
  GetEpointageScheduler,
  GetEpointageSchools,
  GetEpointageSites,
  AddEpointageComment,
  AddEpointageScheduler,
  UpdateEpointageScheduler,
  UpdateEpointageComment,
  GetHoldingAffluence,
  GetToggleToken,
  GetPdfExport,
  UpdateMFAConfig,
  UpdatePreferredZones
} from "@foodi/core";
import { JEST } from "@utils";
import { Container } from "inversify";
import { InMemoryDependencies } from "./InMemoryDependencies";
import { RealDependencies } from "./RealDependencies";

const container = new Container();

export interface Dependencies {
  initializeAuth0Client: InitializeAuth0Client;
  login: Login;
  retrieveTokens: RetrieveTokens;
  refreshToken: RefreshToken;
  getPaymentRequest: GetPaymentRequest;
  getPaymentStatus: GetPaymentStatus;
  getBalance: GetBalance;
  getGuestPaymentProfile: GetGuestPaymentProfile;
  getServices: GetServices;
  getBrand: GetBrand;
  getMarketingCards: GetMarketingCards;
  getPublishedMarketingCardsNew: GetPublishedMarketingCardsNew;
  getOfferTemplates: GetOfferTemplates;
  getPointsOfSaleWithMenu: GetPointsOfSaleWithMenu;
  getTicket: GetTicket;
  getI18nProviderLocale: GetI18nProviderLocale;
  getProfileSectionsData: GetProfileSectionsData;
  getTransactions: GetTransactions;
  getScolapassPayments: GetScolapassPayments;
  getUserInfoForTracking: GetUserInfoForTracking;
  getUserProfile: GetUserProfile;
  getOrders: GetOrders;
  getAllOrders: GetAllOrders;
  getOrder: GetOrder;
  getOrderReceipt: GetOrderReceipt;
  getOrderCC: GetOrderCC;
  getBooking: GetBooking;
  upsertOrder: UpsertOrder;
  confirmOrder: ConfirmOrder;
  getCartSummaryAdmissionAndPrice: GetCartSummaryAdmissionAndPrice;
  getBookingOrders: GetBookingOrders;
  getHoldingAffluence: GetHoldingAffluence;
  getHoldingWithRecipeStatus: GetHoldingWithRecipeStatus;
  getPos: GetPos;
  getOnePos: GetOnePos;
  getPointsOfSale: GetPointsOfSale;
  getOfferSlots: GetOfferSlots;
  getOffer: GetOffer;
  createBookingOrder: CreateBookingOrder;
  updateBookingOrderSlot: UpdateBookingOrderSlot;
  cancelBookingOrder: CancelBookingOrder;
  cancelOrder: CancelOrder;
  getBookingTemplateOffer: GetBookingTemplateOffer;
  updateLanguage: UpdateLanguage;
  updateOptins: UpdateOptins;
  updateOptouts: UpdateOptouts;
  updateVirtualTicket: UpdateVirtualTicket;
  getBrandTheme: GetBrandTheme;
  checkEmail: CheckEmail;
  signUp: SignUp;
  addOrderRating: AddOrderRating;
  discardOrderRatingNotification: DiscardOrderRatingNotification;
  recentOrdersToRate: RecentOrdersToRate;
  getNearHoldings: GetNearHoldings;
  addUserHoldingView: AddUserHoldingView;
  switchUserHoldingView: SwitchUserHoldingView;
  getEdenredDailyBalance: GetEdenredDailyBalance;
  getEdenredAuthorizationURL: GetEdenredAuthorizationURL;
  generateEdenredTokens: GenerateEdenredTokens;
  getUserHoldingView: GetUserHoldingView;
  migrateToHoldingView: MigrateToHoldingView;
  deleteUserHoldingView: DeleteUserHoldingView;
  updateUserHoldingView: UpdateUserHoldingView;
  sendUserEmailVerification: SendUserEmailVerification;
  loginSSO: LoginSSO;
  loginIDP: LoginIDP;
  checkTablesAvailability: CheckTablesAvailability;
  deleteAccount: DeleteAccount;
  getScolapassInvoices: GetScolapassInvoices;
  getInvoiceScolapassFile: GetInvoiceScolapassFile;
  sendResetPasswordEmail: SendResetPasswordEmail;
  getPickupPoints: GetPickupPoints;
  getUserLastUsedPickupPoint: GetUserLastUsedPickupPoint;
  getEpointageServices: GetEpointageServices;
  getEpointageScheduler: GetEpointageScheduler;
  getEpointageSchools: GetEpointageSchools;
  getEpointageSites: GetEpointageSites;
  addEpointageComment: AddEpointageComment;
  updateEpointageComment: UpdateEpointageComment;
  addEpointageScheduler: AddEpointageScheduler;
  updateEpointageScheduler: UpdateEpointageScheduler;
  getToggleToken: GetToggleToken;
  updateMFAConfig: UpdateMFAConfig;
  getPdfExport: GetPdfExport;
  updatePreferredZones: UpdatePreferredZones;
}

export const bindDependencies = (
  ioc: Container,
  dependencies: Dependencies
) => {
  Object.entries(dependencies).forEach(([key, value]) => {
    if (container.isBound(key)) {
      ioc.rebind(key).toConstantValue(value);
    } else {
      ioc.bind(key).toConstantValue(value);
    }
  });

  return ioc;
};

export const setupDependencies = (): Container => {
  if (JEST) {
    bindDependencies(container, InMemoryDependencies());
  } else {
    bindDependencies(container, RealDependencies());
  }
  return container;
};

// configuration.LOCKED = Object.keys(Config).length > 0;
setupDependencies();

export const dependencyContainer = (): Dependencies => ({
  initializeAuth0Client: container.get<InitializeAuth0Client>(
    "initializeAuth0Client"
  ),
  login: container.get<Login>("login"),
  retrieveTokens: container.get<RetrieveTokens>("retrieveTokens"),
  refreshToken: container.get<RefreshToken>("refreshToken"),
  getPaymentRequest: container.get<GetPaymentRequest>("getPaymentRequest"),
  getPaymentStatus: container.get<GetPaymentStatus>("getPaymentStatus"),
  getBalance: container.get<GetBalance>("getBalance"),
  getGuestPaymentProfile: container.get<GetGuestPaymentProfile>(
    "getGuestPaymentProfile"
  ),
  getServices: container.get<GetServices>("getServices"),
  getBrand: container.get<GetBrand>("getBrand"),
  getMarketingCards: container.get<GetMarketingCards>("getMarketingCards"),
  getPublishedMarketingCardsNew: container.get<GetPublishedMarketingCardsNew>(
    "getPublishedMarketingCardsNew"
  ),
  getOfferTemplates: container.get<GetOfferTemplates>("getOfferTemplates"),
  getOfferSlots: container.get<GetOfferSlots>("getOfferSlots"),
  getOffer: container.get<GetOffer>("getOffer"),
  getPointsOfSaleWithMenu: container.get<GetPointsOfSaleWithMenu>(
    "getPointsOfSaleWithMenu"
  ),
  getTicket: container.get<GetTicket>("getTicket"),
  getI18nProviderLocale: container.get<GetI18nProviderLocale>(
    "getI18nProviderLocale"
  ),
  getProfileSectionsData: container.get<GetProfileSectionsData>(
    "getProfileSectionsData"
  ),
  getTransactions: container.get<GetTransactions>("getTransactions"),
  getScolapassPayments: container.get<GetScolapassPayments>(
    "getScolapassPayments"
  ),
  getUserInfoForTracking: container.get<GetUserInfoForTracking>(
    "getUserInfoForTracking"
  ),
  getUserProfile: container.get<GetUserProfile>("getUserProfile"),
  getOrders: container.get<GetOrders>("getOrders"),
  getAllOrders: container.get<GetAllOrders>("getAllOrders"),
  getOrder: container.get<GetOrder>("getOrder"),
  getOrderReceipt: container.get<GetOrderReceipt>("getOrderReceipt"),
  getOrderCC: container.get<GetOrderCC>("getOrderCC"),
  getBooking: container.get<GetBooking>("getBooking"),
  upsertOrder: container.get<UpsertOrder>("upsertOrder"),
  confirmOrder: container.get<ConfirmOrder>("confirmOrder"),
  getCartSummaryAdmissionAndPrice: container.get<GetCartSummaryAdmissionAndPrice>(
    "getCartSummaryAdmissionAndPrice"
  ),
  getBookingOrders: container.get<GetBookingOrders>("getBookingOrders"),
  getHoldingAffluence: container.get<GetHoldingAffluence>("getHoldingAffluence"),
  getHoldingWithRecipeStatus: container.get<GetHoldingWithRecipeStatus>(
    "getHoldingWithRecipeStatus"
  ),
  getPos: container.get<GetPos>("getPos"),
  getOnePos: container.get<GetOnePos>("getOnePos"),
  getPointsOfSale: container.get<GetPointsOfSale>("getPointsOfSale"),
  createBookingOrder: container.get<CreateBookingOrder>("createBookingOrder"),
  updateBookingOrderSlot: container.get<UpdateBookingOrderSlot>(
    "updateBookingOrderSlot"
  ),
  cancelBookingOrder: container.get<CancelBookingOrder>("cancelBookingOrder"),
  cancelOrder: container.get<CancelOrder>("cancelOrder"),
  getBookingTemplateOffer: container.get<GetBookingTemplateOffer>(
    "getBookingTemplateOffer"
  ),
  updateLanguage: container.get<UpdateLanguage>("updateLanguage"),
  updateOptins: container.get<UpdateOptins>("updateOptins"),
  updateOptouts: container.get<UpdateOptouts>("updateOptouts"),
  updateVirtualTicket: container.get<UpdateVirtualTicket>(
    "updateVirtualTicket"
  ),
  getBrandTheme: container.get<GetBrandTheme>("getBrandTheme"),
  checkEmail: container.get<CheckEmail>("checkEmail"),
  signUp: container.get<SignUp>("signUp"),
  addOrderRating: container.get<AddOrderRating>("addOrderRating"),
  discardOrderRatingNotification: container.get<DiscardOrderRatingNotification>("discardOrderRatingNotification"),
  recentOrdersToRate: container.get<RecentOrdersToRate>("recentOrdersToRate"),
  getNearHoldings: container.get<GetNearHoldings>("getNearHoldings"),
  addUserHoldingView: container.get<AddUserHoldingView>("addUserHoldingView"),
  switchUserHoldingView: container.get<SwitchUserHoldingView>(
    "switchUserHoldingView"
  ),
  getEdenredDailyBalance: container.get<GetEdenredDailyBalance>(
    "getEdenredDailyBalance"
  ),
  getEdenredAuthorizationURL: container.get<GetEdenredAuthorizationURL>(
    "getEdenredAuthorizationURL"
  ),
  generateEdenredTokens: container.get<GenerateEdenredTokens>(
    "generateEdenredTokens"
  ),
  getUserHoldingView: container.get<GetUserHoldingView>("getUserHoldingView"),
  migrateToHoldingView: container.get<MigrateToHoldingView>(
    "migrateToHoldingView"
  ),
  deleteUserHoldingView: container.get<DeleteUserHoldingView>(
    "deleteUserHoldingView"
  ),
  updateUserHoldingView: container.get<UpdateUserHoldingView>(
    "updateUserHoldingView"
  ),
  sendUserEmailVerification: container.get<SendUserEmailVerification>(
    "sendUserEmailVerification"
  ),
  loginSSO: container.get<LoginSSO>("loginSSO"),
  checkTablesAvailability: container.get<CheckTablesAvailability>(
    "checkTablesAvailability"
  ),
  deleteAccount: container.get<DeleteAccount>("deleteAccount"),
  loginIDP: container.get<LoginIDP>("loginIDP"),
  getScolapassInvoices: container.get<GetScolapassInvoices>(
    "getScolapassInvoices"
  ),
  getInvoiceScolapassFile: container.get<GetInvoiceScolapassFile>(
    "getInvoiceScolapassFile"
  ),
  sendResetPasswordEmail: container.get<SendResetPasswordEmail>(
    "sendResetPasswordEmail"
  ),
  getPickupPoints: container.get<GetPickupPoints>("getPickupPoints"),
  getUserLastUsedPickupPoint: container.get<GetUserLastUsedPickupPoint>("getUserLastUsedPickupPoint"),
  getEpointageServices: container.get<GetEpointageServices>("getEpointageServices"),
  getEpointageScheduler: container.get<GetEpointageScheduler>("getEpointageScheduler"),
  getEpointageSchools: container.get<GetEpointageSchools>("getEpointageSchools"),
  getEpointageSites: container.get<GetEpointageSites>("getEpointageSites"),
  addEpointageComment: container.get<AddEpointageComment>("addEpointageComment"),
  updateEpointageComment: container.get<UpdateEpointageComment>("updateEpointageComment"),
  addEpointageScheduler: container.get<AddEpointageScheduler>("addEpointageScheduler"),
  updateEpointageScheduler: container.get<UpdateEpointageScheduler>("updateEpointageScheduler"),
  getToggleToken: container.get<GetToggleToken>('getToggleToken'),
  getPdfExport: container.get<GetPdfExport>('getPdfExport'),
  updateMFAConfig: container.get<UpdateMFAConfig>('updateMFAConfig'),
  updatePreferredZones: container.get<UpdatePreferredZones>('updatePreferredZones')
});
