import { Colors } from "@constants";
import { GlobalState } from "@redux";
import { TestIDs } from "@utils";
import React from "react";
import { ActivityIndicator, StyleSheet, TextStyle, ViewStyle } from "react-native";
import { useSelector } from "react-redux";
import { Container, Label } from "./Button.style";

interface IProps {
  forwardTestID?: string;
  label?: string;
  onPress?: (e?: any) => void;
  disabled?: boolean;
  loading?: boolean;
  styleButton?: ViewStyle;
  styleLabel?: TextStyle;
}

export const Button: React.FC<IProps> = ({
  forwardTestID,
  label,
  onPress,
  disabled,
  loading,
  styleButton,
  styleLabel,
}) => {
  const brandTheme = useSelector(
    (state: GlobalState) => state.brandTheme.brandTheme
  );

  return (
    <Container
      testID={forwardTestID}
      color={
        disabled
          ? Colors.disabledBackground
          : brandTheme.buttonActionColors?.backgroundColor ||
            Colors.foodiDefault
      }
      style={styleButton}
      onPress={onPress}
      disabled={disabled}
    >
      {loading ? (
        <>
          <ActivityIndicator
            style={styles.loadingStyle}
            testID={TestIDs.components.Button.views.activityIndicator}
            color={
              brandTheme.buttonActionColors?.textColor || Colors.foodiBlack
            }
          />
          <Label hide>{label}</Label>
        </>
      ) : (
        <Label
          testID={TestIDs.components.Button.texts.label}
          style={styleLabel}
          textColor={
            disabled
              ? Colors.white
              : brandTheme.buttonActionColors?.textColor || Colors.foodiBlack
          }
        >
          {label}
        </Label>
      )}
    </Container>
  );
};

const styles = StyleSheet.create({
  loadingStyle: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    alignItems: "center",
    justifyContent: "center",
  },
});
